import { useState } from 'react';
import { sendFormToBackend } from '../../util/sendFormDataToBackend';

const useGetUpdatesForm = () => {
    const [clickedInputs, setClickedInputs] = useState({});
    const [isJoiningSlack, setIsJoiningSlack] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputs, setInputs] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [hasErrors, setHasErrors] = useState(false);
    const [isSuccessful, setSuccess] = useState(false);
    
    const handleAgreeToConduct = (isAgreeing, closeModal) => {
        setIsJoiningSlack(isAgreeing);
        closeModal();
    }

    const handleFormResponses = (statusCode) => {
        if (statusCode === 201) {
            setHasErrors(false);
            setErrorMessage('');
            setSuccess(true);
            setInputs({
                firstName: '',
                lastName: '',
                email: '',
                isJoiningSlack: false
            });
        }
        if (statusCode === 401 || statusCode === 400) {
            const message = `Oops! A field was not saved/entered correctly. Please fill out the form again.`;
            setHasErrors(true);
            setErrorMessage(message);
        }

        if (statusCode === 500) {
            const message = `Oh no! Something bad happened. Please come back later when we've fixed the problem. Thanks.`;
            setHasErrors(true);
            setErrorMessage(message);
        }
    }

    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

    const handleInputClick = (event) => {
        event.persist();
        setClickedInputs(clickedInputs => ({...clickedInputs, [event.target.name]: true }));
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();

            setHasErrors(false);
            setErrorMessage('');

            try {
                setIsLoading(true);
                sendFormToBackend(inputs, isJoiningSlack).then(
                    function(errorCode) {
                        setIsLoading(false);
                        handleFormResponses(errorCode)
                    })
            } catch (error) {
                handleFormResponses(500)
            }
        }
    }

    const toggleJoiningSlack = () => {
        setIsJoiningSlack(!isJoiningSlack);
    }
    return {
        handleAgreeToConduct,
        handleInputChange,
        handleInputClick,
        toggleJoiningSlack,
        handleSubmit,
        clickedInputs,
        errorMessage,
        hasErrors,
        inputs,
        isSuccessful,
        isJoiningSlack,
        isLoading,
    };
}
export default useGetUpdatesForm;



