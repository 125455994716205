import axios from 'axios';

export const sendFormToBackend = (inputs, isJoiningSlack) => {
    const emailRegex = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    inputs.isJoiningSlack = isJoiningSlack;

    for (const input in inputs) {
        if (Object.hasOwnProperty.call(inputs, input)) {

            if (inputs[input] === undefined ||
                inputs[input] === null) {
                    return new Promise(function (resolve) {
                        return resolve(400);
                    });
            }

            if (input === 'email' && !emailRegex.test(inputs[input])) {
                return new Promise(function (resolve) {
                    return resolve(400);
                });
            }
        }
    }

    const bodyFormData = new FormData();
    bodyFormData.append('first_name', inputs.firstName);
    bodyFormData.append('last_name', inputs.lastName);
    bodyFormData.append('email', inputs.email);
    bodyFormData.append('is_joining_slack', inputs.isJoiningSlack);

    return axios({
        method: 'post',
        url: '/api/community',
        data: bodyFormData,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
    .then(function (response) {
        return response.status;
    })
    .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          return error.response.status;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          return 500;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          return 401
        }
    });
}