import React from 'react'; //, { Fragment, useState } from 'react';

import ConclusionSection from '../ConclusionSection/ConclusionSection';
import Footer from '../../Footer/Footer';
import { HEADER_THEME } from '../../Header/Header';
import Hero from '../../Hero/Hero';
import HeroThumbnail from '../../../images/hero_thumbnail.jpg'
import HeroVideo from '../../../images/hero.mp4';
import HookSection from '../HookSection/HookSection';
import GetUpdates from '../../GetUpdates/GetUpdates';
import GridSection from '../GridSection/GridSection';
import AccordionSection from '../AccordionSection/AccordionSection';

import '../../common/css/Helpers.css';
import '../../common/css/Variables.css';
import './Homepage.css';

const Homepage = () => {
  const heroTitle = (
    <>
      <div className="homepage__title-small-text">Modeling</div>
      <div className="homepage__title-main-text">A Clean Energy Future</div>
      <div className="homepage__title-small-text">for the United States</div>
    </>
  );

  return (
    <>
      <Hero
        title={heroTitle}
        theme={HEADER_THEME.BLUE_BG}
        background={HeroThumbnail}
        video={HeroVideo}
        fullscreen />
      <div className="homepage default-text-style">
        <HookSection />
        <GridSection />
        <AccordionSection />
        <ConclusionSection />
      </div>
      <GetUpdates />
      <Footer />
    </>
  );
}

export default Homepage;
