import React from 'react';
import CodeOfConduct from './CodeOfConduct';

import './CodeOfConduct.css';

const CodeOfConductModalBody = () => {
    const { title, content } = CodeOfConduct;
    return (
      <div className="code-of-conduct__body">
        <h1>{title}</h1>
        <div>{content}</div>
      </div>
    );
}

export default CodeOfConductModalBody;
