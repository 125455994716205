import React from 'react'; //, { Fragment, useState } from 'react';

import { A } from 'hookrouter';
import Citation from '../Citation/Citation';
import Footer from '../Footer/Footer';
import H2Korolev from '../common/H2Korolev/H2Korolev';
import { HEADER_THEME } from '../Header/Header';
import Hero from '../Hero/Hero';
import IconButtonLink from '../common/IconButtonLink/IconButtonLink';
import GetUpdates from '../GetUpdates/GetUpdates';
import MaxWidthContent from '../common/MaxWidthContent/MaxWidthContent';
import { BLOB_STORAGE_URL, OPEN_SOURCE_DOCS_URL } from '../../data/constants';
import { USTestSystemCitationWithLink } from '../../data/citations';

import '../common/css/Helpers.css';
import '../common/css/Variables.css';
import './OpenSourceSoftware.css';

const OpenSourceSoftware = () => {
  const heroTitle = (
    <>
      <div className="open-source-software__title-main-text">Our Open-Source Energy Systems Model</div>
      <div className="open-source-software__title-small-text">Become part of our Open-Source Community</div>
      <IconButtonLink className="open-source-software__github-link" href={OPEN_SOURCE_DOCS_URL}>
        Github Repository
      </IconButtonLink>
    </>
  );

  return (
    <>
      <Hero
        className="open-source-software__hero"
        title={heroTitle}
        theme={HEADER_THEME.BLUE_BG}
        background={BLOB_STORAGE_URL + '/open-source-release/open_source_release_hero_thumbnail.jpg'}
        video={BLOB_STORAGE_URL + '/open-source-release/open_source_release_hero_video.mp4'} />
      <div className="open-source-software default-text-style">
        <MaxWidthContent className="open-source-software__text" maxWidth="900px">
          <h3>Our open-source model of the U.S. power system is high-resolution in both space and time. With this model, users can create highly-realistic simulations of how the power system operates now and in the future.</h3>
          <p>Our model features 82,000 nodes, over 104,000 branches, and 307 TW-miles of transmission capacity. We also use hourly resolution profiles for electric demand and generation potential from wind, solar, and hydro resources. We assembled this model using only publicly available information, so all of the data can be freely used and distributed.</p>
          <p>With this open-source model, our open-source community is able to model scenarios that explore:</p>
          <ul>
            <li>New and evolving technologies</li>
            <li>State-level clean energy policies</li>
            <li>Proposed changes to the transmission infrastructure</li>
          </ul>
          <p>Our <A className="link-blue" href="/">Science Homepage</A> highlights a few of the numerous scenario studies supported by our open-source modeling software. We welcome new collaborators who want to use our model to help augment their research.  Echoing the sentiment of one of our collaborators, this is “just the start of the journey” and we hope you’ll consider joining us in our open-source community.  If you would like to stay in touch with our community’s results or join in the dialogue, please join either our email list or Slack community via the footer below.</p>

          <Citation text={USTestSystemCitationWithLink} />
        </MaxWidthContent>
        <div className="open-source-software__image">
          <MaxWidthContent maxWidth="900px">
            <H2Korolev>Explore our Open-Source Software</H2Korolev>
            <IconButtonLink href={OPEN_SOURCE_DOCS_URL}>
              Github Repository
            </IconButtonLink>
          </MaxWidthContent>
        </div>
      </div>
      <GetUpdates />
      <Footer />
    </>
  );
}

export default OpenSourceSoftware;
