import ApolloClient from 'apollo-boost';

// Contains an instance of our apollo client, which is used to fetch
// scenario data from the backend
// NOTE: We are importing ApolloClient from apollo-boost, not apollo-client
// Apollo-boost comes with sane defaults like apolloInmemoryCache for free!
// Details here: https://www.apollographql.com/docs/react/get-started/#configuration-options

// By default apollo will query <current site>/graphql
// Which is exactly what we want!
const apolloClient = new ApolloClient();

/* Test me with this!
  apolloClient.query({
    query: gql`
      {
        powerGeneration(
          scenarioId: 270
          locationRollup:INTERCONNECT
          timeRollup:YEAR
        ) {
          resourceType
          generation
        }
      }
    `
  })
  .then(result => console.log(result));
*/

export default apolloClient;
