import React from 'react';
import CodeOfConductModalBody from './CodeOfConductModalBody';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import Modal from '../common/Modal/Modal';
import useGetUpdatesForm from '../../hooks/useGetUpdates/useGetUpdatesform';
import useOpenClose from '../../hooks/useOpenClose/useOpenClose';

import './GetUpdates.css';

const GetUpdates = () => {
    const {
        clickedInputs,
        errorMessage,
        hasErrors,
        inputs,
        isSuccessful,
        isJoiningSlack,
        isLoading,
        handleAgreeToConduct,
        handleInputChange,
        handleInputClick,
        handleSubmit,
        toggleJoiningSlack } = useGetUpdatesForm();

    const { open, isOpen, close } = useOpenClose();
    return (
        <div id="get-updates" className="get-updates">
            <div className="form-banner__text">
                <div className="form-banner__title">Get Updates</div>
                <div className="form-banner__description">
                Join the Breakthrough Energy community to go beyond the headlines and get deeper insights
                into the climate challenge we face and the solutions that will get us to zero.
                </div>
            </div>
            <form className="form-banner__form" onSubmit={handleSubmit}>
                <div className="form__row">
                    <label className="form-item">
                        <span className={`form__label ${clickedInputs.firstName ? "has-value" : ""}`}>First Name</span>
                        <input
                            className="form__input"
                            name="firstName"
                            type="text"
                            value={inputs.firstName}
                            onChange={handleInputChange}
                            onClick={handleInputClick}
                            onFocus={handleInputClick}
                            required />
                    </label>
                    <label className="form-item">
                        <span className={`form__label ${clickedInputs.lastName ? "has-value" : ""}`}>Last Name</span>
                        <input
                            className="form__input"
                            name="lastName"
                            type="text"
                            value={inputs.lastName}
                            onChange={handleInputChange}
                            onClick={handleInputClick}
                            onFocus={handleInputClick}
                            required />
                    </label>
                </div>
                <div className="form__row">
                    <label className="form-item">
                        <span className={`form__label ${clickedInputs.email ? "has-value" : ""}`}>Email</span>
                        <input
                            className="form__input"
                            name="email"
                            type="text"
                            value={inputs.email}
                            onChange={handleInputChange}
                            onClick={handleInputClick}
                            onFocus={handleInputClick}
                            required />
                    </label>
                    <label className="form-item">
                        <span className="form__label-slack">Join our Slack community</span>
                        <input
                            name="isJoiningSlack"
                            type="checkbox"
                            checked={isJoiningSlack}
                            onChange={toggleJoiningSlack}
                            onClick={open}/>
                    </label>
                </div>
                { isSuccessful &&
                    <div className="get-updates__success">
                        <IoIosCheckmarkCircle className="get-updates__success-button"/>
                        <div>Thank you</div>
                    </div> }
                { !isLoading && !isSuccessful &&
                        <input className="form__submit" type="submit" value="Get Updates" /> }
                { isLoading && <button className="primary-button-styles loading">Submitting</button> }
                {isOpen && isJoiningSlack && (
                    <Modal className="get-updates__modal" fullscreen="true">
                        <CodeOfConductModalBody/>
                        <button className="secondary-button-styles" name="disagree" onClick={() => { handleAgreeToConduct(false, close)}}>Disagree</button>
                        <button className="primary-button-styles" name="agree" onClick={() => { handleAgreeToConduct(true, close)}}>Agree</button>
                    </Modal>) }
                <div className="get-updates__error-message">{ hasErrors && errorMessage}</div>
            </form>
        </div>
      );
}

export default GetUpdates;