import React from 'react';
import { A } from 'hookrouter';
import classNames from 'classnames';
import logoBlack from '../../icons/BE_Sciences_RGB_Horizontal_Color.svg';
import logoWhite from '../../icons/BE_Sciences_RGB_Horizontal_Reverse-Color.svg';
import logoIcon from '../../icons/BE_Sciences_RGB_Icon.svg';
import { IoIosMenu } from 'react-icons/io';
import useOpenClose from '../../hooks/useOpenClose/useOpenClose';

import '../common/css/Helpers.css';
import '../common/css/Variables.css';
import './Header.css';

export const HEADER_THEME = {
  CLEAR: 'clear',
  WHITE: 'white',
  BLUE_BG: 'blue_bg'
};

const Header = ({ className, theme = HEADER_THEME.CLEAR }) => {
  const { isOpen, toggle } = useOpenClose();
  const headerTheme = isOpen ? HEADER_THEME.WHITE : theme;
  const headerClass = classNames('header', `header__${headerTheme}`, className, { header__nav__open: isOpen });
  const navClass = classNames('header__nav', 'reset-list-styles');
  const logo = headerTheme === HEADER_THEME.WHITE ? logoBlack : logoWhite;

  return (
    <header className={headerClass}>
      <div className="header__top">
        <A className="header__logo reset-link-styles" href="/">
          <img className="header__logo-icon" src={logoIcon} alt="Breakthrough Energy Logo"/>
          <img className="header__logo-svg" src={logo} alt="Breakthrough Energy Sciences"/>
        </A>
      </div>
      <nav>
        <button className="header__menu-button reset-button-styles" onClick={toggle}><IoIosMenu /></button>
        <ul className={navClass}>
          <li><A className="flex-center reset-link-styles" href="/dashboard"><span>Dashboard</span></A></li>
          <li><A className="flex-center reset-link-styles" href="/model"><span>Model</span></A></li>
          <li><A className="flex-center reset-link-styles" href="/open-source-software"><span>Open Source Software</span></A></li>
          <li><A className="flex-center reset-link-styles" href="/publications">Publications</A></li>
          <li><A className="flex-center reset-link-styles" href="/key-findings/macro-grid"><span>Macro Grid Report</span></A></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
