import React from 'react';
import { BLOB_STORAGE_URL, MACRO_GRID_REPORT_URL, OPEN_SOURCE_DOCS_URL, TEXAS_PDF_URL, US_TEST_SYSTEM_PAPER_URL, DEMAND_FLEXIBILITY_URL } from '../../data/constants';
import Citation from '../Citation/Citation';
import { FaDownload } from 'react-icons/fa';
import Footer from '../Footer/Footer';
import GetUpdates from '../GetUpdates/GetUpdates';
import H2Korolev from '../common/H2Korolev/H2Korolev';
import { HEADER_THEME } from '../Header/Header';
import Hero from '../Hero/Hero';
import IconButtonLink, { BUTTON_THEME } from '../common/IconButtonLink/IconButtonLink';
import Link from '../common/Link/Link';
import { MacroGridCitation, TexasPaperCitation, USTestSystemCitation } from '../../data/citations';
import MaxWidthContent, { ALIGN } from '../common/MaxWidthContent/MaxWidthContent';

import '../common/css/Helpers.css';
import '../common/css/Variables.css';
import './Publications.css';

const Publications = () => {
  const openSourceDocsLink = (
    <Link className="link-blue" href={OPEN_SOURCE_DOCS_URL}>open-source software package</Link>
  );

  return (
    <>
      <Hero title="Publications" theme={HEADER_THEME.BLUE_BG} background={BLOB_STORAGE_URL + '/publications/wind_turbine_hills_and_water.jpg'}/>
      <div className="publications default-text-style">

        <section className="publications__section">
          <MaxWidthContent className="publications__details" maxWidth="1200px">
            <H2Korolev className="publications__title">How Much Demand Flexibility Could Have Spared Texas from the 2021 Outage?</H2Korolev>
            <p>Dongqi Wu, Xiangtian Zheng, Ali Menati, Lane Smith, Bainan Xia, Yixing Xu, Chanan Singh, and Le Xie</p>
            <p>Aug. 2022</p>
          </MaxWidthContent>
          <div className="publications__content flex-center">
            <MaxWidthContent className="publications__text" maxWidth="600px" mobileMaxWidth="900px" align={ALIGN.RIGHT} mobileAlign={ALIGN.CENTER}>
              <p>The February 2021 Texas winter power outage has led to hundreds of deaths and billions of dollars in economic losses, largely due to the generation failure and record-breaking electric demand. In this paper, we study the scaling-up of demand flexibility as a means to avoid load shedding during such an extreme weather event. The three mechanisms considered are interruptible load, residential load rationing, and incentive-based demand response. By simulating on a synthetic but realistic large-scale Texas grid model along with demand flexibility modeling and electricity outage data, we identify portfolios of mixing mechanisms that can completely avoid outages, where individual mechanisms may fail due to decaying marginal effects. We also reveal that interruptible load and residential load rationing are complementary, while incentive-based demand response exhibits counterintuitive nonlinear effects on the efficacy of other mechanisms. The quantitative results can provide instructive insights for developing demand response programs against extreme weather conditions.</p>

              {/* TODO: <Citation text={DemandFlexibilityCitation} />*/}
              <IconButtonLink className="publications__link" href={DEMAND_FLEXIBILITY_URL} Icon={FaDownload} theme={BUTTON_THEME.BLUE}>
                Download PDF
              </IconButtonLink>
            </MaxWidthContent>
            <MaxWidthContent className="publications__image" maxWidth="100%" align={ALIGN.LEFT}>
              <img
                src={BLOB_STORAGE_URL + '/publications/demand_flexibility_load_shedding_graphs.png'}
                alt="Forced load shedding curves considering the implementation of demand flexibility of various mechanisms and scales." />
            </MaxWidthContent>
          </div>
        </section>

        <section className="publications__section">
          <MaxWidthContent className="publications__details" maxWidth="1200px">
            <H2Korolev className="publications__title">An open-source extendable model and corrective measure assessment of the 2021 texas power outage</H2Korolev>
            <p>Dongqi Wu, Xiangtian Zheng, Yixing Xu, Daniel Olsen, Bainan Xia, Chanan Singh, Le Xie</p>
            <p>Jul. 2021</p>
          </MaxWidthContent>
          <div className="publications__content flex-center">
            <MaxWidthContent className="publications__text" maxWidth="600px" mobileMaxWidth="900px" align={ALIGN.RIGHT} mobileAlign={ALIGN.CENTER}>
              <p>Unprecedented winter storms that hit across Texas in February 2021 have caused at least 69 deaths and 4.5 million customer interruptions due to the wide-ranging generation capacity outage and record-breaking electricity demand. While much remains to be investigated on what, how, and why such wide-spread power outages occurred across Texas, it is imperative for the broader macro energy community to develop insights for policy making based on a coherent electric grid model and data set. In this paper, we collaboratively release an open-source extendable model that is synthetic but nevertheless provides a realistic representation of the actual energy grid, accompanied by open-source cross-domain data sets. This simplified synthetic model is calibrated to the best of our knowledge based on published data resources. Building upon this open-source synthetic grid model, researchers could quantitatively assess the impact of various policies on mitigating the impact of such extreme events. As an example, in this paper we critically assess several corrective measures that could have mitigated the blackout under such extreme weather conditions. We uncover the regional disparity of load shedding. The analysis also quantifies the sensitivity of several corrective measures with respect to mitigating the severity of the power outage, as measured in Energy-not-Served (ENS). This approach and methodology are generalizable for other regions experiencing significant energy portfolio transitions.</p>

              <Citation text={TexasPaperCitation} />
              <IconButtonLink className="publications__link" href={TEXAS_PDF_URL} Icon={FaDownload} theme={BUTTON_THEME.BLUE}>
                Download PDF
              </IconButtonLink>
            </MaxWidthContent>
            <MaxWidthContent className="publications__image" maxWidth="100%" align={ALIGN.LEFT}>
              <img
                src={BLOB_STORAGE_URL + '/publications/texas_simulated_corrective_measures_sm.png'}
                alt="Conceptual diagram of geographical distribution of four corrective measures in simulation" />
            </MaxWidthContent>
          </div>
        </section>

        <section className="publications__section">
          <MaxWidthContent className="publications__details" maxWidth="1200px">
            <H2Korolev className="publications__title">U.S. Test System with High Spatial and Temporal Resolution for Renewable Integration Studies</H2Korolev>
            <p>Yixing Xu, Nathan Myhrvold, Dhileep Sivam, Kaspar Mueller, Daniel J. Olsen, Bainan Xia, Daniel Livengood, Victoria Hunt, Benjamin Rouille ́ d’Orfeuil, Daniel Muldrew, Merrielle Ondreicka, Megan Bettilyon</p>
            <p>Aug. 2020</p>
          </MaxWidthContent>
          <div className="publications__content flex-center">
            <MaxWidthContent className="publications__text" maxWidth="600px" mobileMaxWidth="900px" align={ALIGN.RIGHT} mobileAlign={ALIGN.CENTER}>
              <p>Planning for power systems with high penetrations of variable renewable energy requires higher spatial and temporal granularity. However, most publicly available test systems are of insufficient fidelity for developing methods and tools for high-resolution planning. This paper presents methods to construct open-access test systems of high spatial resolution to more accurately represent infrastructure and high temporal resolution to represent dynamics of demand and variable resources. To demonstrate, a high-resolution test system representing the United States is created using only publicly available data. This test system is validated by running it in a production cost model, with results compared against historical generation to ensure that they are representative. The resulting open-source test system can support power system transition planning and aid in development of tools to answer questions around how best to reach decarbonization goals, using the most effective combinations of transmission expansion, renewable generation, and energy storage.</p>

              <Citation text={USTestSystemCitation} />
              <IconButtonLink className="publications__link" href={US_TEST_SYSTEM_PAPER_URL} Icon={FaDownload} theme={BUTTON_THEME.BLUE}>
                Download PDF
              </IconButtonLink>
            </MaxWidthContent>
            <MaxWidthContent className="publications__image" maxWidth="100%" align={ALIGN.LEFT}>
              <img
                src={BLOB_STORAGE_URL + '/publications/interconnection_map.png'}
                alt="map of transmission lines in the U.S." />
            </MaxWidthContent>
          </div>
        </section>

        <section className="publications__section">
          <MaxWidthContent className="publications__details" maxWidth="1200px">
            <H2Korolev className="publications__title">A 2030 United States Macro Grid: Unlocking Geographical Diversity to Accomplish Clean Energy Goals</H2Korolev>
            <p>Breakthrough Energy Sciences</p>
            <p>Jan. 2021</p>
          </MaxWidthContent>
          <div className="publications__content flex-center">
            <MaxWidthContent className="publications__text" maxWidth="600px" mobileMaxWidth="900px" align={ALIGN.RIGHT} mobileAlign={ALIGN.CENTER}>
              <p>This Macro Grid report demonstrates an example study from our open-source data (described in the publication above) and {openSourceDocsLink}. This particular study examines combinations of interregional AC and High-Voltage DC (HVDC) transmission upgrades and additions to evaluate the benefits of large-scale transmission expansion for unlocking the geographical diversity of clean energy resources around the U.S. Some of the major contributions explored in this study are: (1) including all three interconnections (Western Interconnection, Eastern Interconnection, and Electric Reliability Council of Texas (ERCOT)) in a contiguous U.S. production cost model; and (2) focusing on an ambitious goal of an electric grid powered by 70% clean energy for the contiguous U.S. by the year 2030. Considering the urgency of fighting climate change and the costs of solar and wind energy falling faster than expected, more progress must be made in the next ten years to ensure the U.S. is on a path to deep decarbonization by 2050.</p>

              <Citation text={MacroGridCitation} />
              <IconButtonLink className="publications__link" href={MACRO_GRID_REPORT_URL} Icon={FaDownload} theme={BUTTON_THEME.BLUE}>
                Download PDF
              </IconButtonLink>
            </MaxWidthContent>
            <MaxWidthContent className="publications__image" maxWidth="100%" align={ALIGN.LEFT}>
              <img
                src={BLOB_STORAGE_URL + '/publications/SEAMS_map.gif'}
                alt="map of simulated power flow in the U.S." />
            </MaxWidthContent>
          </div>
        </section>

      </div>
      <GetUpdates />
      <Footer />
    </>
  );
};

export default Publications;
