import React, { useLayoutEffect } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { usePath, useRoutes } from 'hookrouter';

import AboutTheModel from '../AboutTheModel/AboutTheModel';
import apolloClient from '../../api/apolloClient';
import Dashboard from '../dashboard/Dashboard/Dashboard';
import dashboardSlice from '../../slices/dashboardSlice/dashboardSlice';
import MacroGridInsight from '../insights/MacroGridInsight/MacroGridInsight';
import OpenSourceSoftware from '../OpenSourceSoftware/OpenSourceSoftware';
import Publications from '../Publications/Publications';
import { setPageTracking } from '../../util/util';
import Homepage from '../homepage/Homepage/Homepage';

export const routes = {
  '/': () => <Homepage />,
  '/dashboard': () => <Dashboard />,
  '/key-findings/macro-grid': () => <MacroGridInsight />,
  '/model': () => <AboutTheModel />,
  '/open-source-software': () => <OpenSourceSoftware />,
  '/publications': () => <Publications />
};

// Using combineReducers as prep for adding more stores
const store = configureStore({ reducer: combineReducers({ dashboardSlice })});

const App = () => {
  const routeResult = useRoutes(routes);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const path = usePath();
  setPageTracking(path);

  if (routeResult) {
    // Provider = redux provider
    return (
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          {routeResult}
        </Provider>
      </ApolloProvider>
    );
  } else {
    return '404 Page not found';
  }
}

export default App;
