export const SHOW_ALL_TEXT = 'Show All';

export const INTERCONNECTIONS = {
    'Eastern Interconnect': 'Eastern',
    'Western Interconnect': 'Western',
    'Texas Interconnect': 'Texas'
}

export const STATES = {
    'Eastern Interconnect': {
        'xStart': -12173057.82648251,
        'xEnd': -7433423.674344993,
        'yStart': 5464900.637048257,
        'yEnd': 3675453.300948247
    },
    'Western Interconnect': {
        'xStart': -14639791.542424535,
        'xEnd': -10520160.782933977,
        'yStart': 5669733.224363279,
        'yEnd': 4472798.961279216
    },
    'Texas Interconnect': {
        'xStart': -11943160.201839041,
        'xEnd': -10341535.77728058,
        'yStart': 2906378.8936475967,
        'yEnd': 4442499.783342453
    },
    'Alabama': {
        'xStart': -9868516.27342511,
        'xEnd': -9431756.477687519,
        'yStart': 3502275.7977525424,
        'yEnd': 4184690.611091855
    },
    'Arizona': {
        'xStart': -12813282.823392926,
        'xEnd': -12106742.913385613,
        'yStart': 3643890.8514832533,
        'yEnd': 4471756.103759786
    },
    'Arkansas': {
        'xStart': -10560302.8999981,
        'xEnd': -9955649.935051184,
        'yStart': 3868365.2841805187,
        'yEnd': 4397063.804540028
    },
    'California': {
        'xStart': -13914463.18566358,
        'xEnd': -12649320.62370224,
        'yStart': 3776832.119486178,
        'yEnd': 5219787.732891427
    },
    'Colorado': {
        'xStart': -12179587.438194225,
        'xEnd': -11320160.782933977,
        'yStart': 4399014.706110028,
        'yEnd': 5051871.147197932
    },
    'Connecticut': {
        'xStart': -8216486.717393926,
        'xEnd': -7980630.550026514,
        'yStart': 4996526.146745258,
        'yEnd': 5179264.839990676
    },
    'Delaware': {
        'xStart': -8441283.126371795,
        'xEnd': -8342718.288875141,
        'yStart': 4638882.5728408545,
        'yEnd': 4847043.778126931
    },
    'Florida': {
        'xStart': -9797198.662411965,
        'xEnd': -8860364.329436252,
        'yStart': 2759749.1250167713,
        'yEnd': 3675453.300948247
    },
    'Georgia': {
        'xStart': -9556535.21849439,
        'xEnd': -8962262.462105474,
        'yStart': 3522713.3365459563,
        'yEnd': 4190983.2338073836
    },
    'Idaho': {
        'xStart': -13082702.819109928,
        'xEnd': -12327008.452108957,
        'yStart': 5124974.607374646,
        'yEnd': 6309406.271153008
    },
    'Illinois': {
        'xStart': -10212028.741102265,
        'xEnd': -9662013.608626503,
        'yStart': 4411441.352968154,
        'yEnd': 5262427.749272007
    },
    'Indiana': {
        'xStart': -9822634.108523065,
        'xEnd': -9419882.751181291,
        'yStart': 4532794.151571784,
        'yEnd': 5143558.213608191
    },
    'Iowa': {
        'xStart': -10792646.714792006,
        'xEnd': -9999173.629561538,
        'yStart': 4884996.250712854,
        'yEnd': 5424606.62804186
    },
    'Kansas': {
        'xStart': -11401783.79580731,
        'xEnd': -10490190.378132535,
        'yStart': 4396697.8454782525,
        'yEnd': 4907827.3844824005
    },
    'Kentucky': {
        'xStart': -10013344.099801812,
        'xEnd': -9082320.087648056,
        'yStart': 4326980.5777193,
        'yEnd': 4779160.007813761
    },
    'Louisiana': {
        'xStart': -10498248.183813863,
        'xEnd': -9851244.16387261,
        'yStart': 3328507.471729702,
        'yEnd': 3927297.8391277557
    },
    'Maine': {
        'xStart': -7935863.973401453,
        'xEnd': -7433423.674344993,
        'yStart': 5276528.486819534,
        'yEnd': 6038010.52278454
    },
    'Maryland': {
        'xStart': -8873474.036248758,
        'xEnd': -8322385.004645312,
        'yStart': 4547109.287716993,
        'yEnd': 4850821.613526212
    },
    'Massachusetts': {
        'xStart': -8203154.42725958,
        'xEnd': -7756353.613452433,
        'yStart': 5020232.629696866,
        'yEnd': 5315025.601057823
    },
    'Michigan': {
        'xStart': -10101943.505163161,
        'xEnd': -9096170.236053573,
        'yStart': 5069850.85937894,
        'yEnd': 6203462.088376002
    },
    'Minnesota': {
        'xStart': -10866784.048506945,
        'xEnd': -9918123.355468336,
        'yStart': 5345197.738650786,
        'yEnd': 6380880.431893504
    },
    'Mississippi': {
        'xStart': -10222767.732379092,
        'xEnd': -9787342.65733611,
        'yStart': 3502551.4451768114,
        'yEnd': 4183133.6988744717
    },
    'Missouri': {
        'xStart': -10698002.605420837,
        'xEnd': -9881833.145809984,
        'yStart': 4262928.3511860585,
        'yEnd': 4992541.957676621
    },
    'Montana': {
        'xStart': -12985471.867411707,
        'xEnd': -11514784.767509017,
        'yStart': 5464900.637048257,
        'yEnd': 6341873.701122733
    },
    'Nebraska': {
        'xStart': -11631841.887496756,
        'xEnd': -10560995.474210072,
        'yStart': 4817267.4425356,
        'yEnd': 5360803.461729769
    },
    'Nevada': {
        'xStart': -13392223.880273357,
        'xEnd': -12661644.303590255,
        'yStart': 4130925.7509310883,
        'yEnd': 5194244.528953346
    },
    'New Hampshire': {
        'xStart': -8086591.34317198,
        'xEnd': -7845420.783314095,
        'yStart': 5255142.340991106,
        'yEnd': 5680704.248476983
    },
    'New Jersey': {
        'xStart': -8420443.895056311,
        'xEnd': -8215718.557247709,
        'yStart': 4682138.287026425,
        'yEnd': 5074510.534431645
    },
    'New Mexico': {
        'xStart': -12173057.82648251,
        'xEnd': -11432473.967161817,
        'yStart': 3642300.3918481334,
        'yEnd': 4472798.961279216
    },
    'New York': {
        'xStart': -8923481.089222405,
        'xEnd': -7945793.2823619945,
        'yStart': 4892364.8709090855,
        'yEnd': 5668093.688861119
    },
    'North Carolina': {
        'xStart': -9436298.591210613,
        'xEnd': -8344417.469582608,
        'yStart': 3946156.1188522438,
        'yEnd': 4431486.980484552
    },
    'North Dakota': {
        'xStart': -11624427.842430688,
        'xEnd': -10707751.465486804,
        'yStart': 5728310.343165165,
        'yEnd': 6316640.490982604
    },
    'Ohio': {
        'xStart': -9466077.890831701,
        'xEnd': -8939378.737022083,
        'yStart': 4613254.664841279,
        'yEnd': 5233831.134382319
    },
    'Oklahoma': {
        'xStart': -11513881.465500973,
        'xEnd': -10464455.482591204,
        'yStart': 3932575.991867644,
        'yEnd': 4487086.746166239
    },
    'Oregon': {
        'xStart': -13927690.612837601,
        'xEnd': -12921057.734620105,
        'yStart': 5114113.486687048,
        'yEnd': 5873802.3519715145
    },
    'Pennsylvania': {
        'xStart': -8995843.155357918,
        'xEnd': -8282578.212272801,
        'yStart': 4792896.530623071,
        'yEnd': 5271015.553933835
    },
    'Rhode Island': {
        'xStart': -8009236.375235407,
        'xEnd': -7908986.605926106,
        'yStart': 5021930.051390264,
        'yEnd': 5168217.962177452
    },
    'South Carolina': {
        'xStart': -9305933.782695986,
        'xEnd': -8711492.04332005,
        'yStart': 3740866.021979786,
        'yEnd': 4218207.748301167
    },
    'South Dakota': {
        'xStart': -11626019.599829542,
        'xEnd': -10693892.300202534,
        'yStart': 5192336.879017789,
        'yEnd': 5813958.859326804
    },
    'Tennessee': {
        'xStart': -10101249.817756284,
        'xEnd': -9044124.088307321,
        'yStart': 4113510.4716228386,
        'yEnd': 4442358.740466696
    },
    'Utah': {
        'xStart': -12724173.185145527,
        'xEnd': -12110562.89737193,
        'yStart': 4410975.667426568,
        'yEnd': 5189108.998031364
    },
    'Vermont': {
        'xStart': -8184166.717374718,
        'xEnd': -7948764.844849231,
        'yStart': 5259787.5334619265,
        'yEnd': 5634835.2802480385
    },
    'Virginia': {
        'xStart': -9362064.799156899,
        'xEnd': -8320127.946309736,
        'yStart': 4327934.059966119,
        'yEnd': 4815355.127452324
    },
    'Washington': {
        'xStart': -13939791.542424535,
        'xEnd': -12970985.138498086,
        'yStart': 5669733.224363279,
        'yEnd': 6319320.560194998
    },
    'West Virginia': {
        'xStart': -9226361.386162559,
        'xEnd': -8624281.125967365,
        'yStart': 4442692.432793026,
        'yEnd': 4986577.130533845
    },
    'Wisconsin': {
        'xStart': -10371561.426934795,
        'xEnd': -9654408.650633724,
        'yStart': 5202431.516323939,
        'yEnd': 6022379.338053193
    },
    'Wyoming': {
        'xStart': -12401624.79359278,
        'xEnd': -11544070.420848183,
        'yStart': 4972602.325481697,
        'yEnd': 5661113.652083949
    }
};
