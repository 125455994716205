import React from 'react';
import H2Korolev from '../../common/H2Korolev/H2Korolev';
import IconButtonLink from '../../common/IconButtonLink/IconButtonLink';
import MaxWidthContent, { ALIGN } from '../../common/MaxWidthContent/MaxWidthContent';

import './ConclusionSection.css';

const ConclusionSection = () => {
  return (
    <section className="conclusion-section">
      <MaxWidthContent maxWidth="600px" mobileMaxWidth="900px" align={ALIGN.RIGHT} mobileAlign={ALIGN.CENTER}>
        <H2Korolev>
          A Clean Energy Grid for the Future
        </H2Korolev>
      </MaxWidthContent>
      <MaxWidthContent maxWidth="600px" mobileMaxWidth="900px" align={ALIGN.LEFT} mobileAlign={ALIGN.CENTER}>
        <p>Using our highly complex, open-source model of the national grid, researchers, policymakers, advocates, and anyone interested in a clean energy future can evaluate the impact of different policies and pathways and share their findings. They can see when and where we need transformative action to deliver clean, reliable, and affordable energy across the U.S.  Ultimately, they can find the most efficient and cost-effective way to reach ambitious clean energy goals, reduce greenhouse-gas emissions to net zero by 2050, and avoid the worst impacts of climate change.</p>
        <IconButtonLink className="conclusion-section__dashboard-link" href="/dashboard">
          Go deeper with our Dashboard
        </IconButtonLink>
      </MaxWidthContent>
    </section>
  );
}

export default ConclusionSection;
