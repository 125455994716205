import React, { useState } from 'react';
import ButtonGroup from '../../common/ButtonGroup/ButtonGroup';
import classNames from 'classnames';
import MaxWidthContent, { ALIGN } from '../../common/MaxWidthContent/MaxWidthContent';
import PropTypes from 'prop-types';

import './AccordionSectionChild.css';

const AccordionSectionChild = ({ section, text, graphCaption, graphs }) => {
  const [ activeGraphIdx, setActiveGraphIdx] = useState(0);


  const renderMultiGraphControls = () => {
    if(graphs.length < 2) {
      return null;
    }

    const graphTitles = graphs.map(({ graphTitle }) => graphTitle);
    const graphText = graphs[activeGraphIdx]['graphText'];

    return (
      <div className="accordion-section-child__graph-controls flex-center">
        <ButtonGroup
          className="accordion-section-child__graph-toggle"
          buttonClassName="accordion-section-child__graph-toggle-button"
          items={graphTitles}
          activeIdx={activeGraphIdx}
          onItemClick={idx => setActiveGraphIdx(idx)} />
          <MaxWidthContent maxWidth="600px">
            {graphText}
          </MaxWidthContent>
      </div>
    );
  };

  const renderGraph = ({ graphImageUrl, legend }, idx) => {
    const graphClass = classNames(
      'accordion-section-child__graph__content',
      `accordion-section-child__graph__${section}`,
      { 'hidden': idx !== activeGraphIdx },
    );

    return (
      <div key={`${section}-child-${idx}`} className={graphClass} >
        <div className="accordion-section-child__image-wrapper">
          <img src={graphImageUrl} alt={graphCaption} />
        </div>
        {legend}
      </div>
    );
  }

  return (
    <div className="accordion-section-child">
      <MaxWidthContent
        className="accordion-section-child__text"
        maxWidth="586px" /* 600px - 24px padding on right */
        mobileMaxWidth="900px"
        align={ALIGN.RIGHT}
        mobileAlign={ALIGN.CENTER}>
        <div className="accordion-section-child__text-main">
          {text}
        </div>
      </MaxWidthContent>
      <MaxWidthContent
        className="accordion-section-child__graph"
        childClassName="accordion-section-child__graph-child"
        maxWidth="100%"
        mobileMaxWidth="900px"
        align={ALIGN.LEFT}
        mobileAlign={ALIGN.CENTER}>
        <div className="accordion-section-child__graph-caption small-caps">
          {graphCaption}
        </div>
        <div className="accordion-section-child__graph-bg" >
          {renderMultiGraphControls()}
          {graphs.map(renderGraph)}
        </div>
      </MaxWidthContent>
    </div>
  );
}

AccordionSectionChild.propTypes = {
  section: PropTypes.string,
  text: PropTypes.object,
  graphCaption: PropTypes.string,
  graphs: PropTypes.array
}

export default AccordionSectionChild;
