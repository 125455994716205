import { scaleThreshold } from 'd3-scale';
import { scaleQuantize } from 'd3-scale';

export const COLORS_RGB = {
  clear: [255,  255, 255, 0],
  black: [0,0,0, 200],
  mapGray: [189,189,189,200],
  blueHighlight: [ 0, 144, 255, 50]
};

export const RESOURCE_COLORS_HEX = {
  curtailment: '#4b4cff',
  wind_curtailment: '#78D911',
  wind_offshore_curtailment: '#008080',
  solar_curtailment: '#FFBB45',

  demand: '#ff0200',

  storage: '#FF3270',
  wind: '#78D911',
  wind_offshore: '#008080',
  solar: '#FFBB45',

  ng: '#72818F',
  coal: '#37404C',
  dfo: '#31E8CB',
  other: '#8B36FF',

  biomass: '#006400',
  geothermal: '#CC67F3',
  hydro: '#0090FF',
  nuclear: '#173FA5',
};

export const RESOURCE_COLORS_RGB = {
  biomass: [0, 100, 0, 127],
  coal: [55, 64, 76, 127],
  curtailment: [75, 76, 255, 127],
  demand: [255, 2, 0, 127],
  dfo: [49, 232, 203, 127],
  geothermal: [204, 103, 243, 127],
  hydro: [0, 144, 255, 127],
  ng: [114, 129, 143, 127],
  nuclear: [23, 63, 165, 127],
  wind_offshore_curtailment: [0, 128, 128, 127],
  other: [139, 54, 255, 127],
  solar: [255, 187, 69, 127],
  solar_curtailment: [255, 187, 69, 127],
  storage: [255, 50, 112, 127],
  wind: [120, 217, 17, 127],
  wind_curtailment: [120, 217, 17, 127],
  wind_offshore: [0, 128, 128, 127],
}

export const EMISSIONS_COLORS_RGB = {
  coal: [0, 0, 0, 127],
  ng: [139, 54, 255, 127],
  less: [120, 217, 17, 127],
  more: [255, 133, 99, 127]
}

export const INTERCONNECT_COLORS_RGB = {
  Eastern: [ 139, 55, 255, 180 ],
  Western: [ 0, 144, 255, 180 ],
  Texas: [ 31, 212, 237, 180 ],
  HVDC: [ 253, 50, 112, 180 ]
};

// Used in branch maps
export const createTrafficScale = (min, max) => {
  const increment = (max-min) / 5;
  const domain = []
  for (let i = 0; i < 5; i++) {
    domain.push(min + increment * i);
  }

  return scaleThreshold()
    .domain(domain)
    .range([
      [189,189,189,200], // gray
      [27, 150, 66],  // green
      [166, 218, 106],  // lightgreen
      [247, 247, 41], // yellow
      [252, 174, 97],  // orange
      [214, 33, 29], // red
    ]);
}

// Colors used for LMP maps
const turbo256 = [
  [48,18,59],[49,21,66],[50,24,74],[52,27,81],[53,30,88],[54,33,95],[55,35,101],
  [56,38,108],[57,41,114],[58,44,121],[59,47,127],[60,50,133],[60,53,139],
  [61,55,145],[62,58,150],[63,61,156],[64,64,161],[64,67,166],[65,69,171],
  [65,72,176],[66,75,181],[67,78,186],[67,80,190],[67,83,194],[68,86,199],
  [68,88,203],[69,91,206],[69,94,210],[69,96,214],[69,99,217],[70,102,221],
  [70,104,224],[70,107,227],[70,109,230],[70,112,232],[70,115,235],[70,117,237],
  [70,120,240],[70,122,242],[70,125,244],[70,127,246],[70,130,248],[69,132,249],
  [69,135,251],[69,137,252],[68,140,253],[67,142,253],[66,145,254],[65,147,254],
  [64,150,254],[63,152,254],[62,155,254],[60,157,253],[59,160,252],[57,162,252],
  [56,165,251],[54,168,249],[52,170,248],[51,172,246],[49,175,245],[47,177,243],
  [45,180,241],[43,182,239],[42,185,237],[40,187,235],[38,189,233],[37,192,230],
  [35,194,228],[33,196,225],[32,198,223],[30,201,220],[29,203,218],[28,205,215],
  [27,207,212],[26,209,210],[25,211,207],[24,213,204],[24,215,202],[23,217,199],
  [23,218,196],[23,220,194],[23,222,191],[24,224,189],[24,225,186],[25,227,184],
  [26,228,182],[27,229,180],[29,231,177],[30,232,175],[32,233,172],[34,235,169],
  [36,236,166],[39,237,163],[41,238,160],[44,239,157],[47,240,154],[50,241,151],
  [53,243,148],[56,244,145],[59,244,141],[63,245,138],[66,246,135],[70,247,131],
  [74,248,128],[77,249,124],[81,249,121],[85,250,118],[89,251,114],[93,251,111],
  [97,252,108],[101,252,104],[105,253,101],[109,253,98],[113,253,95],[116,254,92],
  [120,254,89],[124,254,86],[128,254,83],[132,254,80],[135,254,77],[139,254,75],
  [142,254,72],[146,254,70],[149,254,68],[152,254,66],[155,253,64],[158,253,62],
  [161,252,61],[164,252,59],[166,251,58],[169,251,57],[172,250,55],[174,249,55],
  [177,248,54],[179,248,53],[182,247,53],[185,245,52],[187,244,52],[190,243,52],
  [192,242,51],[195,241,51],[197,239,51],[200,238,51],[202,237,51],[205,235,52],
  [207,234,52],[209,232,52],[212,231,53],[214,229,53],[216,227,53],[218,226,54],
  [221,224,54],[223,222,54],[225,220,55],[227,218,55],[229,216,56],[231,215,56],
  [232,213,56],[234,211,57],[236,209,57],[237,207,57],[239,205,57],[240,203,58],
  [242,200,58],[243,198,58],[244,196,58],[246,194,58],[247,192,57],[248,190,57],
  [249,188,57],[249,186,56],[250,183,55],[251,181,55],[251,179,54],[252,176,53],
  [252,174,52],[253,171,51],[253,169,50],[253,166,49],[253,163,48],[254,161,47],
  [254,158,46],[254,155,45],[254,152,44],[253,149,43],[253,146,41],[253,143,40],
  [253,140,39],[252,137,38],[252,134,36],[251,131,35],[251,128,34],[250,125,32],
  [250,122,31],[249,119,30],[248,116,28],[247,113,27],[247,110,26],[246,107,24],
  [245,104,23],[244,101,22],[243,99,21],[242,96,20],[241,93,19],[239,90,17],
  [238,88,16],[237,85,15],[236,82,14],[234,80,13],[233,77,13],[232,75,12],
  [230,73,11],[229,70,10],[227,68,10],[226,66,9],[224,64,8],[222,62,8],
  [221,60,7],[219,58,7],[217,56,6],[215,54,6],[214,52,5],[212,50,5],[210,48,5],
  [208,47,4],[206,45,4],[203,43,3],[201,41,3],[199,40,3],[197,38,2],[195,36,2],
  [192,35,2],[190,33,2],[187,31,1],[185,30,1],[182,28,1],[180,27,1],[177,25,1],
  [174,24,1],[172,22,1],[169,21,1],[166,20,1],[163,18,1],[160,17,1],[157,16,1],
  [154,14,1],[151,13,1],[148,12,1],[145,11,1],[142,10,1],[139,9,1],[135,8,1],
  [132,7,1],[129,6,2],[125,5,2],[122,4,2]
];

export const LMP_COLORS_RGB = scaleQuantize().domain([20,45]).range(turbo256);

// Tool for dev use. Please convert your colors with this and export them above
// to save cpu cycles at runtime
export const hexToRGB = (hex, alpha) => {
  const r = parseInt("0x" + hex[1] + hex[2]);
  const g = parseInt("0x" + hex[3] + hex[4]);
  const b = parseInt("0x" + hex[5] + hex[6]);

  return alpha ? [ r, g, b, alpha ] : [ r, g, b ];
};
