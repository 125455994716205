import React from 'react';
import { A } from 'hookrouter';
import { BLOB_STORAGE_URL, MACRO_GRID_REPORT_URL, OPEN_SOURCE_DOCS_URL } from '../../../data/constants';
import Citation from '../../Citation/Citation';
import { FaDownload } from 'react-icons/fa';
import Footer from '../../Footer/Footer';
import GetUpdates from '../../GetUpdates/GetUpdates';
import { HEADER_THEME } from '../../Header/Header';
import Hero from '../../Hero/Hero';
import IconButtonLink from '../../common/IconButtonLink/IconButtonLink';
import Link from '../../common/Link/Link';
import { MacroGridCitation } from '../../../data/citations';
import MaxWidthContent from '../../common/MaxWidthContent/MaxWidthContent';

import '../../common/css/Helpers.css';
import '../../common/css/Variables.css';
import './MacroGridInsight.css';

const BASE_URL = BLOB_STORAGE_URL + '/insights/macro-grid/';

const MacroGridInsight = () => {
  const macroGridReportLink = <Link className="link-blue" href={MACRO_GRID_REPORT_URL}>here</Link>;
  const dashboardLink = <A className="macro-grid-insight__dashboard-link link-blue" href="/dashboard">our Dashboard</A>;
  const openSourceDocsLink = <Link className="link-blue" href={OPEN_SOURCE_DOCS_URL}>open-source software</Link>;
  const title = (
    <div>
      A United States Macro Grid
      <IconButtonLink className="macro-grid-insight__download-pdf-button" href={MACRO_GRID_REPORT_URL} Icon={FaDownload}>
        Download the Report
      </IconButtonLink>
    </div>
  )
  return (
    <>
      <Hero title={title} theme={HEADER_THEME.BLUE_BG} background={BASE_URL + 'wind_turbine_hills.jpg'}/>
      <MaxWidthContent className="macro-grid-insight default-text-style" maxWidth="900px">
        <h3>If every state achieves its 2030 clean electricity goal, the U.S. will only cut emissions from electricity generation by 6% compared to current (2020) levels. That is clearly not enough to mitigate climate change and provide clean, affordable electricity to all. Instead, we need to think bigger; for example, what would it take to get to 70% clean electricity by 2030? Achieving this goal could reduce electrical GHG emissions by 46% compared to 2020.</h3>
        <img src={BASE_URL + 'energy_carbon_stacks.png'} alt="Nivo bar stacks" />
        <p>First and foremost, this would require large investments in new clean generation capacity. Generation capacity alone, however, is not enough: if we want to be able to get this energy to where it is needed, we need a big enhancement in the nation’s transmission capacity. A new, stronger, national-scale transmission network that can support such a significant buildout of clean energy is sometimes referred to as a Macro Grid. The transmission network may only need an expansion of less than 10% to meet current 2030 clean electricity goals, but meeting this more ambitious goal of 70% clean electricity by 2030 could require an expansion on the order of 30% or more.</p>
        <p>Not all transmission is built alike: High-Voltage Direct Current transmission (HVDC) is more efficient at moving large amounts of power long distances, bridging asynchronous interconnections, and offering increased control over power flows within each synchronous electric grid, making it an excellent candidate for improving the ‘backbone’ of a stronger future grid. Conventional AC transmission is more cost-effective for the many smaller, shorter lines that connect generators and customers to the grid’s backbone. Our research explores four different designs for a Macro Grid, ranging from AC-only to an ambitious HVDC network design. Each of these designs can meet the 70% clean electricity goals, in different ways. A technical report on the details of these four designs is available {macroGridReportLink}, and the results of these four designs can be explored in more depth on {dashboardLink}. Let’s look at the differences between the full-AC design and the HVDC network design:</p>
        <div className="macro-grid-insight__transmission-maps full-page-width">
          <img src={BASE_URL + 'ac_transmission_map.png'} alt="zoomable map" />
          <p><i>All AC Transmission Upgrades</i></p>
          <img src={BASE_URL + 'hvdc_diff_map.png'} alt="zoomable map" />
          <p><i>Difference between All-AC and HVDC+AC designs</i></p>
          <img src={BASE_URL + 'hvdc_transmission_map.png'} alt="zoomable map" />
          <p><i>HVDC+AC transmission upgrades</i></p>
        </div>
        <p>The All-AC design requires 36% more AC transmission capacity than the current state of the grid in 2020, while the HVDC+AC design requires only 23% more AC capacity; however, it also requires sixteen new long-distance HVDC lines, each with a capacity of 8 GW, more than twice as much as the largest HVDC line in the U.S. today (each route may also be constructed from several smaller parallel HVDC lines with a total of 8 GW of capacity, to reduce the impact of losing a single line). Overall, construction costs are similar (approximately $220B), with transmission upgrades distributed more broadly in the all-AC design and more concentrated in a few large projects in the HVDC network design.</p>
        <p>Without these transmission upgrades, approximately 50% of the energy that the new renewable generators could produce will be wasted, since the transmission network can’t move the energy to the demand centers. This causes the grid to continue to rely on fossil fuel sources, making it less likely that these renewable generators will be built. With the transmission upgrades, approximately 80% of the renewable energy gets used. This increase in use of renewable energy decreases the amount of energy coming from fossil fuels by approximately half, along with similar reductions in associated carbon emissions and local air pollution.</p>
        <p>The operation of the HVDC network design illustrates how the different regions of the grid have complementary renewable generation resources that could be shared if they were connected; the Western Interconnection typically has a surplus of solar power to export to the East during the daylight hours, and the Eastern Interconnection typically has a surplus of wind energy to export to the West during the rest of the day. These complementary profiles allow the country as a whole to operate more efficiently than each interconnection by itself.</p>
        <img src={BASE_URL + 'power_transfer_heatmap.png'} alt="power flow" />
        <p>This page highlights just a few of the numerous scenario studies supported by the synthetic U.S. transmission grid modeled via our {openSourceDocsLink}. We welcome new collaborators that want to explore these or any other scenario that is of interest to them.  Echoing the sentiment of one of our collaborators, this is “just the start of the journey” and we hope you’ll consider joining us in our Breakthrough Energy Sciences community.  If you would like to stay in touch with our community’s results or join in the dialogue, please join either our email list or Slack community via the footer below.</p>

        <Citation text={MacroGridCitation} />
      </MaxWidthContent>
      <MaxWidthContent className="macro-grid-insight__bottom-image" maxWidth="900px" />
      <GetUpdates />
      <Footer />
    </>
  )
};

export default MacroGridInsight;
