import React from 'react';

import { A } from 'hookrouter';
import { FaArrowRight } from 'react-icons/fa';
import { GRID_INFO } from '../../../data/homepage_content';
import H2Korolev from '../../common/H2Korolev/H2Korolev';
import MaxWidthContent from '../../common/MaxWidthContent/MaxWidthContent';

import '../../common/css/Helpers.css';
import './GridSection.css';

/* TODO: grid and goals sections share almost all code and styles */
const GridSection = () => {
  const { title, graphTitle, introText, graphImageUrl, legend, text } = GRID_INFO['grid'];

  return (
    <section className="grid-section">
      <MaxWidthContent maxWidth="900px">
        <H2Korolev>{title}</H2Korolev>
        {introText}
      </MaxWidthContent>
      <MaxWidthContent childClassName="grid-section__graph" maxWidth="1200px">
        <div className="small-caps">{graphTitle}</div>
        <div className="grid-section__graph-wrapper">
          <img src={graphImageUrl} alt={graphTitle} />
        </div>
        {legend}
      </MaxWidthContent>
      <MaxWidthContent maxWidth="900px">
        {text}
        <A className="grid-section__model-link flex-center reset-link-styles" href="/model">
          <FaArrowRight className="grid-section__model-link__arrow" />
          Learn more about our Model
        </A>
      </MaxWidthContent>
    </section>
  );
}

export default GridSection;
