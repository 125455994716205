import React from 'react';
import { IoMdMail } from 'react-icons/io';
import GradientBorder from '../common/GradientBorder/GradientBorder';
import Link from '../common/Link/Link';
import logo from '../../icons/logo.svg';
import PropTypes from 'prop-types';


import '../common/css/Helpers.css';
import './Footer.css';

const Footer = ({ className }) => {
  return (
    <>
      <GradientBorder />
      <div className="footer__container">
        <div className={'footer ' + className}>
            <picture className="footer__logo-wrapper">
              <source srcSet={logo} media="(min-width: 910px)"/>
              <img className="footer__logo-svg" src={logo} alt="Breakthrough Energy logo"/>
            </picture>
            <div className="footer__nav-col">
            <div className="footer__nav-col-title">Stay Connected</div>
              <div className="footer__email">
                <IoMdMail className="footer__email-icon"/>
                <a className="reset-link-styles" href="mailto:sciences@breakthroughenergy.org">
                  <span>Email</span>
                </a>
              </div>
            </div>
            <nav className="footer__links">
              <Link className="reset-link-styles" href="https://www.breakthroughenergy.org/privacy">Privacy</Link>
              <Link className="reset-link-styles" href="https://www.breakthroughenergy.org/terms">Terms</Link>
            </nav>
            <div className="footer__copywright">© 2021 Breakthrough Energy. All Rights Reserved.</div>
        </div>
      </div>
    </>
  );
}

Footer.propTypes = {
  className: PropTypes.string
}

Footer.defaultProps = {
  className: '',
}
export default Footer;
