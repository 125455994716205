import Link from "../components/common/Link/Link";
import { US_TEST_SYSTEM_PAPER_URL } from "./constants";

import '../components/common/css/Helpers.css';

export const USTestSystemCitation = "Yixing Xu, Nathan Myhrvold, Dhileep Sivam, Kaspar Mueller, Daniel J. Olsen, Bainan Xia, Daniel Livengood, Victoria Hunt, Benjamin Rouille ́ d’Orfeuil, Daniel Muldrew, Merrielle Ondreicka, Megan Bettilyon. “US test system with high spatial and temporal resolution for renewable integration studies.” In 2020 IEEE Power & Energy Society General Meeting (PESGM), 2020, (pp. 1-5). doi: 10.1109/PESGM41954.2020.9281850.";

export const USTestSystemCitationWithLink = <>Yixing Xu, Nathan Myhrvold, Dhileep Sivam, Kaspar Mueller, Daniel J. Olsen, Bainan Xia, Daniel Livengood, Victoria Hunt, Benjamin Rouille ́ d’Orfeuil, Daniel Muldrew, Merrielle Ondreicka, Megan Bettilyon. <Link className="link-blue" href={US_TEST_SYSTEM_PAPER_URL}>“US test system with high spatial and temporal resolution for renewable integration studies.”</Link> In 2020 IEEE Power & Energy Society General Meeting (PESGM), 2020, (pp. 1-5). doi: 10.1109/PESGM41954.2020.9281850.</>

export const TexasPaperCitation = "Dongqi Wu, Xiangtian Zheng, Yixing Xu, Daniel Olsen, Bainan Xia, Chanan Singh, Le Xie. 2021. “An open-source extendable model and corrective measure assessment of the 2021 texas power outage.” Advances in Applied Energy, 4, 100056. doi: 10.1016/j.adapen.2021.100056";

export const MacroGridCitation = "Yixing Xu, Daniel Olsen, Bainan Xia, Dan Livengood, Victoria Hunt, Yifan Li, and Lane Smith. 2021. “A 2030 United States Macro Grid: Unlocking Geographical Diversity to Accomplish Clean Energy Goals.” Seattle, WA: Breakthrough Energy Sciences.";

// TODO:
export const DemandFlexibilityCitation = "";