export const SHOW_ALL_TEXT = 'Show All';

export const INTERCONNECTIONS = {
    'Eastern Interconnect': 'Eastern',
    'Western Interconnect': 'Western',
    'Texas Interconnect': 'Texas'
}

// 'xmin': left,
// 'ymin': bottom,
// 'xmax': right,
// 'ymax': top

export const STATES = {
  'Show All': {
    'xmin': -124.76306799999999,
    'ymin': 24.523096,
    'xmax': -66.96466,
    'ymax': 49.002494
  },
  'Eastern Interconnect': {
    'xmin': -109.060253,
    'ymin': 24.523096,
    'xmax': -66.96466,
    'ymax': 49.002494
  },
  'Western Interconnect': {
    'xmin': -124.76306799999999,
    'ymin': 31.332301,
    'xmax': -102.041524,
    'ymax': 49.002494
  },
  'Texas Interconnect': {
    'xmin': -106.645646,
    'ymin': 25.837377,
    'xmax': -93.508292,
    'ymax': 36.500704
  },
  'Alabama': {
    'xmin': -88.47322700000001,
    'ymin': 30.223334,
    'xmax': -84.88908,
    'ymax': 35.008028
  },
 'Arizona': {
    'xmin': -114.81651000000001,
    'ymin': 31.332177,
    'xmax': -109.045223,
    'ymax': 37.004259999999995
  },
 'Arkansas': {
    'xmin': -94.617919,
    'ymin': 33.004106,
    'xmax': -89.64439499999999,
    'ymax': 36.4996
  },
 'California': {
    'xmin': -124.40959099999999,
    'ymin': 32.534156,
    'xmax': -114.13121100000001,
    'ymax': 42.009518
  },
 'Colorado': {
    'xmin': -109.060253,
    'ymin': 36.992426,
    'xmax': -102.041524,
    'ymax': 41.003444
  },
 'Connecticut': {
    'xmin': -73.727775,
    'ymin': 40.980144,
    'xmax': -71.78699399999999,
    'ymax': 42.050587
  },
 'Delaware': {
    'xmin': -75.788658,
    'ymin': 38.451013,
    'xmax': -75.048939,
    'ymax': 39.839007
  },
 'Florida': {
    'xmin': -87.634938,
    'ymin': 24.523096,
    'xmax': -80.031362,
    'ymax': 31.000888
  },
 'Georgia': {
    'xmin': -85.605165,
    'ymin': 30.357851,
    'xmax': -80.839729,
    'ymax': 35.000659000000006
  },
 'Idaho': {
    'xmin': -117.243027,
    'ymin': 41.988057,
    'xmax': -111.04356399999999,
    'ymax': 49.001146000000006
  },
 'Illinois': {
    'xmin': -91.513079,
    'ymin': 36.970298,
    'xmax': -87.49475600000001,
    'ymax': 42.508481
  },
 'Indiana': {
    'xmin': -88.09776,
    'ymin': 37.771741999999996,
    'xmax': -84.78457900000001,
    'ymax': 41.760591999999995
  },
 'Iowa': {
    'xmin': -96.63970400000001,
    'ymin': 40.375501,
    'xmax': -90.140061,
    'ymax': 43.501196
  },
 'Kansas': {
    'xmin': -102.051744,
    'ymin': 36.993016,
    'xmax': -94.588413,
    'ymax': 40.003161999999996
  },
 'Kentucky': {
    'xmin': -89.57150899999999,
    'ymin': 36.497129,
    'xmax': -81.96497099999999,
    'ymax': 39.147458
  },
 'Louisiana': {
    'xmin': -94.04314699999999,
    'ymin': 28.928609,
    'xmax': -88.817017,
    'ymax': 33.019457
  },
 'Maine': {
    'xmin': -71.083924,
    'ymin': 42.977764,
    'xmax': -66.949895,
    'ymax': 47.459686
  },
 'Maryland': {
    'xmin': -79.487651,
    'ymin': 37.911716999999996,
    'xmax': -75.048939,
    'ymax': 39.723043
  },
 'Massachusetts': {
    'xmin': -73.508142,
    'ymin': 41.237964,
    'xmax': -69.928393,
    'ymax': 42.886589
  },
 'Michigan': {
    'xmin': -90.418136,
    'ymin': 41.696118,
    'xmax': -82.413474,
    'ymax': 48.2388
  },
 'Minnesota': {
    'xmin': -97.239209,
    'ymin': 43.499356,
    'xmax': -89.491739,
    'ymax': 49.384358
  },
 'Mississippi': {
    'xmin': -91.65500899999999,
    'ymin': 30.173942999999998,
    'xmax': -88.097888,
    'ymax': 34.996052
  },
 'Missouri': {
    'xmin': -95.774704,
    'ymin': 35.995683,
    'xmax': -89.098843,
    'ymax': 40.613640000000004
  },
 'Montana': {
    'xmin': -116.050003,
    'ymin': 44.358221,
    'xmax': -104.039138,
    'ymax': 49.00139
  },
 'Nebraska': {
    'xmin': -104.053514,
    'ymin': 39.999998,
    'xmax': -95.30829,
    'ymax': 43.001708
  },
 'Nevada': {
    'xmin': -120.005746,
    'ymin': 35.001857,
    'xmax': -114.039648,
    'ymax': 42.002207
  },
 'New Hampshire': {
    'xmin': -72.557247,
    'ymin': 42.69699,
    'xmax': -70.610621,
    'ymax': 45.305476
  },
 'New Jersey': {
    'xmin': -75.559614,
    'ymin': 38.928519,
    'xmax': -73.893979,
    'ymax': 41.357423
  },
 'New Mexico': {
    'xmin': -109.050173,
    'ymin': 31.332301,
    'xmax': -103.001964,
    'ymax': 37.000232000000004
  },
 'New York': {
    'xmin': -79.762152,
    'ymin': 40.496103000000005,
    'xmax': -71.85621400000001,
    'ymax': 45.01585
  },
 'North Carolina': {
    'xmin': -84.32186899999999,
    'ymin': 33.842316,
    'xmax': -75.460621,
    'ymax': 36.588117
  },
 'North Dakota': {
    'xmin': -104.0489,
    'ymin': 45.935054,
    'xmax': -96.554507,
    'ymax': 49.000574
  },
 'Ohio': {
    'xmin': -84.82015899999999,
    'ymin': 38.403202,
    'xmax': -80.518693,
    'ymax': 41.977523
  },
 'Oklahoma': {
    'xmin': -103.002565,
    'ymin': 33.615833,
    'xmax': -94.430662,
    'ymax': 37.002206
  },
 'Oregon': {
    'xmin': -124.566244,
    'ymin': 41.991794,
    'xmax': -116.463504,
    'ymax': 46.292035
  },
 'Pennsylvania': {
    'xmin': -80.519891,
    'ymin': 39.7198,
    'xmax': -74.689516,
    'ymax': 42.269859999999994
  },
 'Rhode Island': {
    'xmin': -71.86277199999999,
    'ymin': 41.146339000000005,
    'xmax': -71.12057,
    'ymax': 42.018798
  },
 'South Carolina': {
    'xmin': -83.35391,
    'ymin': 32.0346,
    'xmax': -78.54203000000001,
    'ymax': 35.215402000000005
  },
 'South Dakota': {
    'xmin': -104.057698,
    'ymin': 42.479634999999995,
    'xmax': -96.436589,
    'ymax': 45.94545
  },
 'Tennessee': {
    'xmin': -90.310298,
    'ymin': 34.982972,
    'xmax': -81.6469,
    'ymax': 36.678118
  },
 'Utah': {
    'xmin': -114.05296200000001,
    'ymin': 36.997968,
    'xmax': -109.041058,
    'ymax': 42.001567
  },
 'Vermont': {
    'xmin': -73.43774,
    'ymin': 42.726853000000006,
    'xmax': -71.464555,
    'ymax': 45.016659000000004
  },
 'Virginia': {
    'xmin': -83.675395,
    'ymin': 36.540738,
    'xmax': -75.242266,
    'ymax': 39.466012
  },
 'Washington': {
    'xmin': -124.76306799999999,
    'ymin': 45.543541,
    'xmax': -116.915989,
    'ymax': 49.002494
  },
 'West Virginia': {
    'xmin': -82.644739,
    'ymin': 37.201483,
    'xmax': -77.71951899999999,
    'ymax': 40.638801
  },
 'Wisconsin': {
    'xmin': -92.888114,
    'ymin': 42.491983000000005,
    'xmax': -86.805415,
    'ymax': 47.080621
  },
 'Wyoming': {
    'xmin': -111.05688799999999,
    'ymin': 40.994746,
    'xmax': -104.05216,
    'ymax': 45.005904
  }
};
