import React from 'react';
import { BLOB_STORAGE_URL, NREL_SEAMS_STUDY_URL, US_TEST_SYSTEM_DATA_URL, US_TEST_SYSTEM_PAPER_URL } from '../../data/constants';
import Citation from '../Citation/Citation';
import Footer from '../Footer/Footer';
import GetUpdates from '../GetUpdates/GetUpdates';
import H2Korolev from '../common/H2Korolev/H2Korolev';
import { HEADER_THEME } from '../Header/Header';
import Hero from '../Hero/Hero';
import Link from '../common/Link/Link';
import MaxWidthContent from '../common/MaxWidthContent/MaxWidthContent';
import { USTestSystemCitation } from '../../data/citations';

import '../common/css/Helpers.css';
import '../common/css/Variables.css';
import './AboutTheModel.css';

const AboutTheModel = () => {
  const datasetLink = <Link className="link-blue" href={US_TEST_SYSTEM_DATA_URL}>here</Link>;
  const datasetDevLink = <Link className="link-blue" href={US_TEST_SYSTEM_PAPER_URL}>here</Link>;
  const nrelSeamsLink = <Link className="link-blue" href={NREL_SEAMS_STUDY_URL}>NREL Seams Study</Link>;

  return (
    <>
      <Hero title="About Our Model" theme={HEADER_THEME.BLUE_BG} background={ BLOB_STORAGE_URL + '/model/wind_turbine_construction.jpg'}/>
      <MaxWidthContent className="about-the-model default-text-style" maxWidth={'900px'}>
        <H2Korolev>How We Generated These Results</H2Korolev>
        <p>These results were obtained by running a year-long simulation for the year 2030, under a variety of different assumptions of generation and transmission infrastructure. The simulation is a production cost model, running a multi-period DC-approximated optimal power flow for each day of the year, using weather-synchronized profiles for electrical demand, hydro, solar, and wind. The model of the United States power system is an open-source, high-resolution model that is statistically similar to the true network but contains no confidential information. The dataset can be found {datasetLink}, and a paper describing the development of this dataset can be found {datasetDevLink}.
        </p>
        <Citation text={USTestSystemCitation} />
        {/* TODO: add link to github repo once we open source */}
        <H2Korolev>Different Infrastructure Scenarios</H2Korolev>
        {/* TOOD: find a way to make image larger but still responsive */}
        <MaxWidthContent className="about-the-model__img-wrapper full-page-width" maxWidth={'1200px'}>
          <img src={BLOB_STORAGE_URL + '/model/scenario_tree.png'} alt="chart describing how our scenarios are related" />
        </MaxWidthContent>
        <h3>Scenarios modeling current conditions</h3>
        <ul>
          <li><strong>2016:</strong> A simulation of the grid in 2016, used to validate that our simulation produces results that are consistent with historical data</li>
          <li><strong>2020 (Current grid):</strong> An updated version of the grid, incorporating new generators and generator retirements since 2016</li>
        </ul>

        <h3>Scenario families meeting current clean energy goals</h3>
        <ul>
          <li><strong>2030 Independent:</strong> Each state adds generation capacity to meet its own clean energy goals</li>
          <li><strong>2030 Collaborative:</strong> States work together to meet shared goals for clean energy, installing new clean energy generation in the best locations</li>
          <li><strong>2030 Collaborative with Storage:</strong> States work together, and also add 13.25 GW of energy storage capacity, reflecting current state-level mandates</li>
          <li><strong>2030 Collaborative with Renewables at Retirements:</strong> States work together, and prioritize adding new renewable capacity at the location of previous generator retirements, to make use of the pre-existing transmission capacity to the bulk grid</li>
          <li><strong>2030 Eastern Offshore Wind:</strong> States work together, and 13.72 GW of offshore wind projects are added off of the East Coast, reflecting projects in development and state-level mandates</li>
        </ul>

        <h3>Scenario families meeting ambitious clean energy goals</h3>
        <ul>
          <li><strong>2030 Ambitious goals (AC upgrades only):</strong> states in the Western Interconnection match California's goal (60% of energy from wind & solar), states in the Eastern Interconnection who have clean energy targets match New York's goal (70% of energy from wind, solar, & hydro), and the Texas grid adds three times as much new wind and solar capacity as is currently projected, resulting in a penetration of around 45%</li>
          <li><strong>2030 Ambitious goals (HVDC network + AC upgrades):</strong> goals are the same as above, but are aided by the addition of a continent-spanning HVDC network overlay, connected to the existing AC network.</li>
        </ul>

        <H2Korolev>Balancing New Generation and New Transmission</H2Korolev>
        <p>For each scenario family, we have three different scenarios which meet the goals in different ways: a high-renewables-capacity scenario, a high-transmission-upgrades scenario, and a balanced scenario. To determine the mix of renewables and transmission that meets the clean energy goals, we start by upgrading the renewable capacity to three different levels, each of which has the potential to produce more clean energy than the goal, if not for curtailment (typically caused by transmission congestion, or by oversupply of renewables when demand is low). Then for each scenario, we iteratively run a year-long production cost model, identify the branches with the highest potential to reduce congestion (highly correlated with renewable curtailment), and upgrade these branches. We repeat this process until there is adequate transmission capacity so that the clean energy facilities can deliver the target amount of energy to electricity consumers.</p>
        <p>The cases with greatest renewable capacity require the least amount of new transmission to meet the clean energy goal, but also end up with the highest amount of curtailment, since more capacity is being used to produce the same amount of energy. The cases with lowest renewable capacity require the most transmission capacity, but result in much less wasted energy from the renewable plants that are built.</p>

        <H2Korolev>Model Caveats</H2Korolev>
        <ul>
          <li>Transmission capacity expansion occurs by identifying and upgrading the most congested transmission lines</li>
          <li>Generation capacity expansion occurs by scaling up the current renewable generation locations</li>
        </ul>

        <H2Korolev>Future Work</H2Korolev>
        <ul>
          <li>Adding unit commitment process to our model</li>
          <li>Adding new transmission corridors (currently we only upgrade existing transmission corridors)</li>
          <li>Adding new renewable generation locations</li>
          <li>Scenarios with significant increases in energy storage, hydrogen, demand flexibility, etc.</li>
          <li>Capacity expansion models with different levels of spatial and temporal resolution to co-optimize transmission and generation</li>
        </ul>
        <H2Korolev>References</H2Korolev>
        <h3 className="references-h3">HVDC Design:</h3>
        <ul>
          <li>Dale L Osborn. “Designing self-contingent HVDC systems with the AC systems”. In: IEEE Power and Energy Society General Meeting (PESGM). IEEE. 2016, pp. 1–4.</li>
          <li>Armando Luis Figueroa-Acevedo. “Opportunities and benefits for increasing transmission capacity between the US eastern and western interconnections”. PhD dissertation. Iowa State University, 2017.</li>
          <li>{nrelSeamsLink}</li>
          <li>A. L. Figueroa-Acevedo et al. “Design and Valuation of High-Capacity HVDC Macrogrid Transmission for the Continental US”. In: IEEE Transactions on Power Systems (2020).</li>
        </ul>
      </MaxWidthContent>
      <GetUpdates />
      <Footer />
    </>
  );
};

export default AboutTheModel;