import React from 'react';
import H2Korolev from '../../common/H2Korolev/H2Korolev';
import MaxWidthContent from '../../common/MaxWidthContent/MaxWidthContent';

import './HookSection.css';

const HookSection = () => {
  return (
    <section className="hook-section">
      <MaxWidthContent className="hook-section__content-wrapper" maxWidth="900px">
        <H2Korolev className="hook-section__h2">How do we transform our electrical grid to reach net zero by 2050?</H2Korolev>
        <h3>Electricity is a major contributor to climate change, accounting for one-third of all greenhouse-gas emissions in the United States.</h3>
        <p>To reach net-zero emissions by 2050 and avoid the most disastrous impacts of climate change, we need to modernize our national power grid so it can generate and distribute clean, reliable, and affordable energy to everyone. With the right tools and planning, we will get it done. </p>
      </MaxWidthContent>
    </section>
  );
}

export default HookSection;
