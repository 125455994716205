import React, { useEffect, useState } from 'react';
import Accordion from '../../common/Accordion/Accordion';
import { ACCORDION_INFO } from '../../../data/homepage_content';
import H2Korolev from '../../common/H2Korolev/H2Korolev';
import { getHeaderHeight } from '../../../util/util';
import MaxWidthContent from '../../common/MaxWidthContent/MaxWidthContent';
import usePrevious from '../../../hooks/usePrevious/usePrevious';
import AccordionSectionChild from '../AccordionSectionChild/AccordionSectionChild';

import '../../common/css/Helpers.css';
import '../../common/css/Variables.css';
import './AccordionSection.css';

const AccordionSection = () => {
  const [ openSection, setOpenSection ] = useState(null); //TODO remember to set to null
  const previousOpenSection = usePrevious(openSection)
  const getAccordionClass = section => `accordion-section__${section}`;

  // Scroll to top of accordion section when we set a new active section
  useEffect(() => {
    if (!openSection) {
      return;
    }

    const headerHeight = getHeaderHeight()

    // if new section is above, good
    // if if new section  is below, need to subtract open accordion height
    // how to figure out if it's below?

    const accordionClass = getAccordionClass(openSection);
    const el = document.getElementsByClassName(accordionClass)[0];
    const yCoord = el.offsetTop;

    // If the section above us is closing, we need to adjust the y coordinates
    let futureYCoord = yCoord;
    const sectionList = Object.keys(ACCORDION_INFO);
    if (previousOpenSection && sectionList.indexOf(previousOpenSection) < sectionList.indexOf(openSection)) {
      // We need to fetch the previously open section and get its height
      const prevAccordionClass = getAccordionClass(previousOpenSection);
      const prevSectionParent = document.getElementsByClassName(prevAccordionClass)[0];
      const prevSectionContent = prevSectionParent.getElementsByClassName('accordion-section__accordion__child')[0];
      const prevSectionHeight = prevSectionContent.getBoundingClientRect().height

      futureYCoord = yCoord - prevSectionHeight;
      const yPosition = el.getBoundingClientRect().y - headerHeight;

      // Seems to make things a bit smoother
      //prevSectionParent.scrollIntoView({ behavior: 'smooth' })
      prevSectionContent.scrollIntoView({ block: 'end', behavior: 'smooth' })

      setTimeout(() => window.scrollTo({ top: futureYCoord - headerHeight, behavior: 'smooth' }), 2 * Math.sqrt(yPosition));
    } else {
      window.scrollTo({ top: futureYCoord - headerHeight, behavior: 'smooth' });
    }
  }, [ openSection, previousOpenSection ]);

  const renderAccordionSection = (section, idx) => {
    const onToggle = () => {
      // if it's currently open, then we need to close
      if (openSection === section) {
        setOpenSection(null);
      } else {
        setOpenSection(section);
      }
    }

    const toggleEl = (
      <MaxWidthContent className="accordion-section__accordion__toggle" maxWidth="1200px">
        <div className="small-caps">0{idx + 1}</div>
        <H2Korolev className="accordion-section__toggle-title">{ACCORDION_INFO[section]['title']}</H2Korolev>
      </MaxWidthContent>
    );

    return (
      <Accordion
        key={section}
        className={'accordion-section__accordion ' + getAccordionClass(section)}
        childClassName="accordion-section__accordion__child"
        isOpenOverride={openSection === section}
        toggleEl={toggleEl}
        onToggle={onToggle} >
        <AccordionSectionChild section={section} {...ACCORDION_INFO[section]} />
      </Accordion>
    );
  }

  return (
    <section className="accordion-section">
      <MaxWidthContent className="accordion-section__intro" maxWidth="900px">
        <H2Korolev>What It Will Take</H2Korolev>
        <p>To reach net-zero emissions by 2050, we will have to move as much activity as we can—like cars, manufacturing processes, and heating and cooling systems—onto the electric grid, while making sure the power they need is clean, available, and affordable. That will require massive updates to our electric grid, including connecting more zero-emissions technologies like solar, wind, and nuclear and adding more storage, transmission, and generation capacity.</p>
        <p>By depicting the most efficient ways to make these changes, our model can help leaders set the right policies for the right places to reach net zero by 2050.</p>
      </MaxWidthContent>
      <div>
        {Object.keys(ACCORDION_INFO).map(renderAccordionSection)}
      </div>
    </section>
  );
};

export default AccordionSection;
