import React from 'react';
import PropTypes from 'prop-types';

import './Citation.css';

const Citation = ({ text }) => {
  return (
    <div className="citation">
      Recommended Citation:
      <p className="citation__text">{text}</p>
    </div>
  );
};

Citation.propTypes = {
  text: PropTypes.node.isRequired
}

export default Citation;