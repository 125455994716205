import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import Header, { HEADER_THEME } from '../Header/Header';

import './Hero.css';
import '../common/css/Variables.css';
import ScrollObserver from '../common/ScrollObserver/ScrollObserver';
import H2Korolev from '../common/H2Korolev/H2Korolev';

// The background param can be an external URL or a local image
const Hero = ({ className, title, theme = HEADER_THEME.WHITE, background = null, video = null, fullscreen = false }) => {
  const [ heroIsVisible, setHeroIsVisible ] = useState(true);

  const onIntersectionEvent = (entries) => {
    const heroVisible = entries[0].isIntersecting;
    setHeroIsVisible(heroVisible);
  }

  const heroClass = classNames(className, 'hero', `hero__${theme}`, { 'hero__visible': heroIsVisible });
  const heroWrapperClass = classNames('hero__wrapper', { 'hero__fullscreen': fullscreen });
  const heroBgClass = classNames('hero__bg', { 'hero__has-video': video });
  const heroBgStyle = background && { backgroundImage: `url(${background})` };
  const headerTheme = heroIsVisible ? theme : HEADER_THEME.WHITE;

  const renderVideo = useMemo(() => {
    return (video &&
      <div dangerouslySetInnerHTML={{
        __html: `
          <video class="hero__video" autoPlay loop muted playsinline>
            <source src=${video} type="video/mp4"/>
            Video Playback Error
          </video>`
      }} />);
  }, [ video ]);

  return (
    <div className={heroClass}>
      {/* Rendering the bg first allows us to render a thumbnail before the video loads */}
      <div className={heroBgClass} style={heroBgStyle}></div>
      <Header className="hero__header" theme={headerTheme} />
      {renderVideo}
      <ScrollObserver threshold={0.66} handleIntersect={onIntersectionEvent}>
        <div className={heroWrapperClass}>
          <H2Korolev className="hero__title">
            {title}
          </H2Korolev>
        </div>
      </ScrollObserver>
    </div>
  );
};

export default Hero;
